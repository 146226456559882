<template>
    <div class="greybackgroundpayscreen">
        <div v-if="showCurrentTask" style="height:100%">

            <b-row>
                <b-col><br /><br />
                    <b-row>
                        <b-col class="Dashboard-Go-Back dashboarmapp-left-pad" col lg="2" @click="importGoback">Go
                            Back</b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <center><br /><br /><br /><v-icon large style="font-size:78px;">mdi-file-excel</v-icon></center>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <br /><br />
                            <center>
                                <h2>{{ currentTask.title }}</h2>
                            </center>
                            <br />
                            <center>
                                <h4>{{currentTask.file_size}} - {{formatDate(currentTask.created_at) }}</h4>
                            </center>
                            <br />
                            <center>
                                <div class="Pay-Completed-Rectangle"><span>Completed</span></div>
                            </center>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3">
                        </b-col>
                        <b-col cols="6" class="pay-bottom-line-hightlight">
                            <br />
                        </b-col>
                        <b-col cols="3">
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3">
                        </b-col>
                        <b-col cols="6" class="pay-displayText-Title">
                            <br />Stats overview
                        </b-col>
                        <b-col col="3">
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3">
                        </b-col>
                        <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText">
                            <br />Total Records
                        </b-col>
                        <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText paypushTextright">
                            <br />{{totalRecords()}}
                        </b-col>
                        <b-col cols="3">
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3">
                        </b-col>
                        <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText">
                            <br />Records Improved
                        </b-col>
                        <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText paypushTextright">
                            <br />{{improved()}}
                        </b-col>
                        <b-col cols="3">
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3">
                        </b-col>
                        <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText">
                            <br />Duplicates Found
                        </b-col>
                        <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText paypushTextright">
                            <br />{{dups()}}
                        </b-col>
                        <b-col cols="3">
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3">
                        </b-col>
                        <b-col cols="6" class="">
                            <br /><br /><br />
                            <center><a href="#" @click="doStats()">See more statistics</a></center>
                        </b-col>
                        <b-col cols="3">
                        </b-col>
                    </b-row>

                </b-col>
                <b-col style="background-color:#f5f9fd">
                    <b-col>

                        <b-row>
                            <b-col>
                                <center><br /><br /><br /><br />
                                    <h1>Purchase to Download your Data</h1>
                                </center>
                            </b-col>
                        </b-row>
                        <br /><br /><br />
                        <b-row>
                            <b-col cols="3"></b-col>
                            <b-col cols="6">
                                <b-row class="pay-rowinbox">
                                    <b-col>
                                        <center><v-icon large style="font-size:38px;">mdi-file-excel</v-icon></center>
                                    </b-col>
                                    <b-col>
                                        <center>
                                            <h2>{{ currentTask.title }}</h2>
                                        </center>
                                    </b-col>
                                    <b-col>
                                        <center><a href="#" @click="doStats()"><br />View Sample</a></center>
                                    </b-col>
                                </b-row>


                            </b-col>
                            <b-col cols="3"></b-col>
                        </b-row>
                        <b-row v-if="currentCharge.charges">
                            <b-col cols="3">
                            </b-col>
                            <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText">
                                <br />Sub Total
                            </b-col>
                            <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText paypushTextright">
                                <br />£{{formatPrice(currentCharge.charges.subtotal)}}
                            </b-col>
                            <b-col cols="3">
                            </b-col>
                        </b-row>
                        <b-row v-if="currentCharge.charges">
                            <b-col cols="3">
                            </b-col>
                            <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText">
                                <br />VAT
                            </b-col>
                            <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText paypushTextright">
                                <br />£{{formatPrice(currentCharge.charges.vat)}}
                            </b-col>
                            <b-col cols="3">
                            </b-col>
                        </b-row>
                        <b-row v-if="currentCharge.charges">
                            <b-col cols="3">
                            </b-col>
                            <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText">
                                <br />Total
                            </b-col>
                            <b-col cols="3" class="pay-bottom-line-hightlight pay-displayText paypushTextright">
                                <br />£{{formatPrice(currentCharge.charges.total)}}
                            </b-col>
                            <b-col cols="3">
                            </b-col>
                        </b-row>
                        <b-row v-if="currentCharge.charges">
                            <b-col cols="3">
                            </b-col>
                            <b-col cols="6">
                                <br /><br />Please enter your credit card details below to pay to download your full
                                data.<br /><br />
                                <h4>Name on Card</h4>
                                <b-form-input class="form-control" label="Name" v-model="name_on_card"
                                    v-validate="'required|alpha_spaces'" name="name" placeholder="" />
                                <div id="nameerrors" ref="nameerrors" role="alert"></div>

                                <h4>Credit Card Details</h4>

                                <div id="card" ref="card" class="form-control"></div>
                                <div id="carderrors" ref="carderrors" role="alert"></div>

                            </b-col>
                            <b-col cols="3">
                            </b-col>
                        </b-row>

                        <b-row v-if="currentCharge.charges">
                            <b-col cols="3">
                            </b-col>
                            <b-col cols="6">
                                <center><b-button class="payButton" @click="pay" v-if="!processing">
                                        <v-icon style="color:white">mdi-lock</v-icon>
                                        Confirm payment: £{{formatPrice(currentCharge.charges.total)}}
                                    </b-button></center>
                                <center style="width:100%" v-if="processing">....Processing Payment.....</center>
                            </b-col>
                            <b-col cols="3">
                            </b-col>
                        </b-row>




                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />


                    </b-col>
                </b-col>
            </b-row>



        </div>


    </div>
</template>

<script>

import { mapGetters } from "vuex";
import { UPDATE_TASKS, GET_CHARGES } from "@/core/services/store/tasks.module";
import ApiService from "@/core/services/api.service";

let stripeValid = false;
let displayError = null;

export default {
    name: "dashboard",
    components: {
    },
    data() {
        return {
            // Note 'isActive' is left out and will not appear in the rendered table
            filter: '',
            currentTask: null,
            showCurrentTask: false,
            stripeOptions: {
                // see https://stripe.com/docs/stripe.js#element-options for details
            },
            stripeKey: '',
            stripeObject: null,
            stripElement: null,
            stripeCard: null,
            name_on_card: '',
            charges: {
                minCharge: false,
                subTotal: 0,
                vat: 0,
                total: 0,
                noOfRecords: 0,
                chargePerRecord: 0,
            }
        }
    },
    mounted() {
        this.getData('', '');

        var id = this.$route.params.id;
        this.$store.dispatch(GET_CHARGES, {
            id,
        });
        setTimeout(() => {
            this.setStripData();
        }, 500);
    },
    methods: {
        setStripData() {
            this.stripeKey = this.currentCharge.stripeKey;
            this.stripeOptions = this.currentCharge.stripeOptions;
            this.charges = this.currentCharge.charges;

            this.stripeObject = window.Stripe(this.stripeKey);
            this.stripElement = this.stripeObject.elements();

            var style = {
                base: {

                }
            };

            this.stripeCard = this.stripElement.create('card', { style: style });

            this.stripeCard.mount(this.$refs.card);

            this.stripeCard.addEventListener('change', function (event) {

                displayError = document.getElementById('carderrors');;

                if (event.error) {
                    displayError.textContent = event.error.message;
                    stripeValid = false;
                } else {
                    displayError.textContent = '';
                    stripeValid = true;
                }

            });
        },
        pay() {


            displayError = document.getElementById('carderrors');
            displayError.textContent = '';
            displayError = document.getElementById('nameerrors');
            displayError.textContent = '';

            var takepayment = true;
            var router = this.$router;
            var currenttask = this.currentTask.id;
            var booking = {
                stripe: null,
                id: this.currentTask.id,
                name_on_card: this.name_on_card,
                total: this.charges.total,
            }

            if (!stripeValid) {
                this.displayError = document.getElementById('carderrors');
                this.displayError.textContent = 'Please fill in your card details';
                takepayment = false;
            }

            if (this.name_on_card == '') {
                this.displayError = document.getElementById('nameerrors');
                this.displayError.textContent = 'Please fill in your name';
                takepayment = false;
            }


            if (takepayment) {

                this.stripeObject.createToken(this.stripeCard, {
                    name: this.name_on_card
                }).then(function (result) {

                    booking.stripe = result;
                    if (result.error) {

                        // Inform the user if there was an error
                        var errorElement = document.getElementById('carderrors');
                        errorElement.textContent = result.error.message;

                        self.showSpinner = false;

                    } else {

                        self.processing = true;


                        ApiService
                            .post('apps/todo/task/pay/' + booking.id, booking)
                            .then(() => {


                                router.push({ name: 'dashboardreview', params: { id: currenttask } });


                            }).catch((error) => {
                                // Inform the user if there was an error
                                var errorElement = document.getElementById('carderrors');
                                errorElement.textContent = 'Sorry there was a problem processing your card';

                                self.showSpinner = false;

                                if (error.response.data) {
                                    errorElement.textContent = error.response.data.error.message;

                                }
                            });


                    }


                });

            }

        },
        getData(filter) {

            this.$store.dispatch(UPDATE_TASKS, {
                filter,
            }).then(() => {

                this.currentTasks.forEach(record => {

                    if (record.id == this.$route.params.id) {
                        this.currentTask = record;
                        this.showCurrentTask = true;
                    }


                });

            });

        },
        importGoback() {
            this.$router.push({ name: 'dashboard' });
        },
        doStats() {
            this.$router.push({ name: 'dashboardreview', params: { id: this.currentTask.id } });
        },
        doData() {
            this.$router.push({ name: 'dashboardreview', params: { id: this.currentTask.id } });
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(date);
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        totalRecords() {
            var retVal = '';
            var currentTaskResults = JSON.parse(this.currentTask.results);
            retVal = parseInt(currentTaskResults.noOfRecords);
            return retVal;
        },
        improved() {
            var retVal = '';
            var currentTaskResults = JSON.parse(this.currentTask.results);
            retVal = parseInt(currentTaskResults.noChangedRecords);
            return retVal;
        },
        dups() {
            var retVal = '';
            var currentTaskResults = JSON.parse(this.currentTask.results);
            retVal = parseInt(currentTaskResults.noOfAddressDups);
            return retVal;
        },
    },
    computed: {
        ...mapGetters(["currentTasks", "currentCharge"]),
        getMappings() {
            return this.mappings;
        }
    }
};
</script>

<style>
.Pay-Completed-Rectangle {
    width: 107px;
    height: 30px;
    padding: 7px 25px 8px;
    opacity: 0.2;
    border-radius: 17px;
    background-color: #33b985;
}

.Pay-Completed-Rectangle span {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: black;
    opacity: 1 !important;
}

.pay-bottom-line-hightlight {
    border-bottom: solid 1px #e9eef3;
}

.pay-displayText-Title {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #3f6f86;

}

.pay-displayText {
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #3f6f86;

}

.paypushTextright {
    text-align: right;
}

.dashboardpay {
    padding: 0;
}

.dashboardpay .container-fluid {
    padding: 0 0 0 25px;
}

.pay-rowinbox {
    margin: 25px 0 45px;
    padding: 23px 30px 23px 28px;
    border-radius: 8px;
    box-shadow: 0 1px 8px 0 rgba(233, 238, 243, 0.71);
    border: solid 1px rgba(232, 232, 232, 0.8);
    background-color: white;
}

.greybackgroundpayscreen {
    min-height: 100%;
}

.payButton {
    width: 500px;
    height: 64px;
    margin: 60px 0 0;
    border-radius: 8px;
    background-color: #33b985 !important;
    color: white !important;
    font-size: 15px;
    font-weight: 500;
}

#carderrors,
#nameerrors {
    color: #cc0000;
    font-weight: 700;
}</style>